import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×4\\@85% 1RM`}</p>
    <p>{`Single Leg Bent Leg Bridge 4×4/leg`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p><em parentName="p">{`*`}{`with a 45/25`}{`#`}{` bumper plate…`}</em></p>
    <p>{`20-OH Walking Lunges (10 per leg)`}</p>
    <p>{`20-Squats holding plate`}</p>
    <p>{`20-G2OH with plate`}</p>
    <p>{`20-Jumps Over plate`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      